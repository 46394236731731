import { render, staticRenderFns } from "./ModulePricesBargain.vue?vue&type=template&id=57bf8300&"
import script from "./ModulePricesBargain.vue?vue&type=script&lang=js&"
export * from "./ModulePricesBargain.vue?vue&type=script&lang=js&"
import style0 from "./ModulePricesBargain.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModuleHeader: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/ModuleHeader.vue').default,AppLink: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AppLink.vue').default,ModuleFooter: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/ModuleFooter.vue').default})
