import { render, staticRenderFns } from "./MagazineModule_A1_B3.vue?vue&type=template&id=426c1558&"
import script from "./MagazineModule_A1_B3.vue?vue&type=script&lang=js&"
export * from "./MagazineModule_A1_B3.vue?vue&type=script&lang=js&"
import style0 from "./MagazineModule_A1_B3.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MagazineHeader: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/MagazineHeader.vue').default,MagazineArticlePhoto: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/MagazineArticlePhoto.vue').default,MagazineArticleText: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/MagazineArticleText.vue').default,MagazineFooter: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/MagazineFooter.vue').default})
