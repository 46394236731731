import { render, staticRenderFns } from "./InquiryModal.vue?vue&type=template&id=776a364e&"
import script from "./InquiryModal.vue?vue&type=script&lang=js&"
export * from "./InquiryModal.vue?vue&type=script&lang=js&"
import style0 from "./InquiryModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewChatButton: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/NewChatButton.vue').default})
